import { Container } from 'react-bootstrap';
import './style.css';
import { FaLaptop, FaClipboardList } from "react-icons/fa";
import { TiDeviceLaptop } from "react-icons/ti";
import { GoChecklist } from "react-icons/go";
import Button from '../../Components/Button/index.jsx';

const Service = () => {
    const animationName = 'zoom-in';
    const animationDuration = 1000;
    return (
        <div className='service-div'>
            <Container>
                <h1 className='service-hd'>
                    Our Services
                </h1>
                <div className='service-card-container'>
                    <div className='service-card-div col-lg-6 col-md-6 col-sm-6 col-xs-12' data-aos={animationName} data-aos-duration={animationDuration}>
                        <div className='sub-service-card-div'>
                            <FaLaptop className="service-logo" />
                            <h3>Online Class Help</h3>
                            <p>
                                When balancing rigorous exam preparation with online classes becomes overwhelming, you can confidently request our assistance to take your online class. We ensure 100% attendance and active participation in discussions, prioritizing your privacy with strict policies. Simply entrust us with 'take my online class for me' and embrace a worry-free academic journey.
                                <Button compclass='nav-chat-btn mt-5' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                            </p>
                        </div>
                    </div>
                    <div className='service-card-div col-lg-6 col-md-6 col-sm-6 col-xs-12' data-aos={animationName} data-aos-duration={animationDuration}>
                        <div className='sub-service-card-div'>
                            <FaClipboardList className="service-logo" />
                            <h3>Online Homework Help</h3>
                            <p>
                                Transform your homework into a standout achievement! Our team of skilled professionals offers 100% original and flawless online homework assistance. Known for our responsive customer service and punctual delivery, Takeonlinecourse offers top-tier online homework help at affordable rates. Stay ahead with us!
                                <Button compclass='nav-chat-btn mt-5' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                            </p>
                        </div>
                    </div>
                    <div className='service-card-div col-lg-6 col-md-6 col-sm-6 col-xs-12' data-aos={animationName} data-aos-duration={animationDuration}>
                        <div className='sub-service-card-div'>
                            <GoChecklist className="service-logo" />
                            <h3>Online Exam Help</h3>
                            <p>
                                Our Online Exam Help offers a stress-free solution to excel in your online exams. Our skilled team of exam specialists ensures top grades, providing a reliable service where you can pay someone to take your exam. We ensure secure payments and offer a full refund guarantee if our service does not meet your expectations.
                                <Button compclass='nav-chat-btn mt-5' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                            </p>
                        </div>
                    </div>
                    <div className='service-card-div col-lg-6 col-md-6 col-sm-6 col-xs-12' data-aos={animationName} data-aos-duration={animationDuration}>
                        <div className='sub-service-card-div'>
                            <TiDeviceLaptop className="service-logo" />
                            <h3>Online Course Help</h3>
                            <p>
                                Facing challenges that require hiring someone to take my online course? We specialize in ensuring your online course is completed flawlessly by subject experts. Takeonlinecourse guarantees an A grade, regardless of the course's complexity.
                                <Button compclass='nav-chat-btn mt-5' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                            </p>
                        </div>
                    </div>
                    <div className='service-card-div col-lg-12 col-md-12 col-sm-12 col-xs-12' data-aos={animationName} data-aos-duration={animationDuration}>
                        <div className='sub-service-card-div'>
                            <FaClipboardList className="service-logo" />
                            <h3>Online Assignment Help</h3>
                            <p>
                                Seeking trustworthy online assignment assistance with looming deadlines? Takeonlinecourse provides the ultimate solution. Connect with our expert online assignment helpers for comprehensive support across diverse subjects and disciplines. Let us ease your assignment workload while you enjoy peace of mind.
                                <Button compclass='nav-chat-btn mt-5' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Service;