import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const AccountingClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Hire a Professional to Manage My Accounting Course for Me!"
                    text={`
        Are you struggling to move forward in your academic journey because of challenges with an online accounting course? Don’t let your accounting class hold you back. The answer lies in using our accounting course support service, delivered by skilled professionals and subject matter experts. So, don't delay—place your order right here.
        `}
                    list={[
                        'Assured Grades',
                        'Available 24/7',
                        'Tailored Packages for Students',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Policy for Reimbursement'}
                Icon2Text={'Domain Specialists'}
                Icon3Text={'100% Private'}
                Icon4Text={'Timeliness Guaranteed'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default AccountingClassHelpPage;