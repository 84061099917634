import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const OnlineClassHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Attain Full Attendance Using Our Online Course Assistance!"
                    text={`
                        Takeexamhelp provides expert involvement in your virtual course,
                        aiming to elevate your online learning experience. We gladly accept
                        your request to manage your online course for you.
                        `}
                    list={[
                        'Experienced Course Specialists',
                        'Secure Payment System',
                        'Strict Confidentiality Policy',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Ensured Privacy'}
                Icon2Text={<>Engaged <br /> Involvement</>}
                Icon3Text={<>Proficient <br /> Professionals</>}
                Icon4Text={<>Guaranteed Excellent <br /> Marks</>}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default OnlineClassHelpPage;