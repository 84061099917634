import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const TeasExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Ensure a 95% Success Rate with Our Help for Managing My TEAS Examination"
                    text={`
            Experiencing anxiety about your TEAS test? Approach it confidently with assurance that we're here to assist you. Our superior TEAS exam support guarantees seamless achievement of your objective to 'hire someone to do my TEAS exam'. Therefore, strive for excellence and excel in your TEAS assessment by availing our internet-based TEAS exam services!
        `}
                    list={[
                        'Top-Notch Service',
                        'Competitive Rates',
                        'Guaranteed Results',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Continuous Availability'}
                Icon2Text={'No additional fees'}
                Icon3Text={'Assured Reimbursement'}
                Icon4Text={'Customer Delight'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default TeasExamHelpPage;