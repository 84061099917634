import { Container } from 'react-bootstrap';
import './style.css';
import { HiCurrencyDollar } from "react-icons/hi";
import { TbHours24 } from "react-icons/tb";
import { RiSecurePaymentFill } from "react-icons/ri";
import { GrCertificate } from "react-icons/gr";


const Sec2 = ({ Icon1Text, Icon2Text, Icon3Text, Icon4Text }) => {
    const animationName = 'zoom-in';
    const animationDuration = 1000;
    return (
        <div className='sec-2-div'>
            <Container  className='sec-2-container-1'>
                <h1>Why we're the go to training provider for you</h1>
            </Container>
            <Container className='sec-2-container'>
                <div className='sec-2-box' data-aos={animationName} data-aos-duration={animationDuration}>
                    <div className='sub-sec-2-box'>
                        <HiCurrencyDollar className='sec2-icon' />
                    </div>
                    <span>{Icon3Text}</span>
                </div>
                <div className='sec-2-box' data-aos={animationName} data-aos-duration={animationDuration}>
                    <div className='sub-sec-2-box'>
                        <TbHours24 className='sec2-icon' />
                    </div>
                    <span>{Icon1Text}</span>
                </div>
                <div className='sec-2-box' data-aos={animationName} data-aos-duration={animationDuration}>
                    <div className='sub-sec-2-box'>
                        <RiSecurePaymentFill className='sec2-icon' />
                    </div>
                    <span>{Icon4Text}</span>
                </div>
                <div className='sec-2-box' data-aos={animationName} data-aos-duration={animationDuration}>
                    <div className='sub-sec-2-box'>
                        <GrCertificate className='sec2-icon' />
                    </div>
                    <span>{Icon2Text}</span>
                </div>
            </Container>
        </div>
    )
}

export default Sec2;