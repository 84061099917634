import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const AlgebraClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Engage a Professional for My Algebra Course!"
                    text={`
        Algebra classes often present considerable challenges, leaving numerous students struggling. If you're grappling with algebra, explore our algebra course support service to engage a specialist to oversee my algebra course. Let our algebra professionals manage your online algebra courses with discretion and expertise.
        `}
                    list={[
                        'Assured Academic Performance',
                        'Available at All Times',
                        'Tailored Student Packages',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Policy for Reimbursement'}
                Icon2Text={'Domain Specialists'}
                Icon3Text={'100% Private'}
                Icon4Text={'Timeliness Guaranteed'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default AlgebraClassHelpPage;