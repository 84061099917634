import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const GedExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Get Top-Notch GRE Help from Our Professional GRE Exam Instructors!"
                    text={`
                          Are you worried about acing the GRE? There's no need to be concerned anymore. Our GRE exam experts are ready to step in whenever you need someone to take the GRE for you.
                        `}
                    list={[
                        'Outstanding Results',
                        'Highly Qualified Experts',
                        'Very Affordable Rates',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Recurring Customer Support'}
                Icon2Text={'Expert-Level Team'}
                Icon3Text={'Economical Packages'}
                Icon4Text={'Refund Policy'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default GedExamHelpPage;