import { Container } from 'react-bootstrap';
import './style.css';
import { useState } from "react";
// import Icon1 from '../../Assets/Sec-2/icon-1.png'
// import Icon2 from '../../Assets/Sec-2/icon-2.png'
// import Icon3 from '../../Assets/Sec-2/icon-3.png'
// import Icon4 from '../../Assets/Sec-2/icon-4.png'
import { HiCurrencyDollar } from "react-icons/hi";
import { TbHours24 } from "react-icons/tb";
import { RiSecurePaymentFill } from "react-icons/ri";
import { GrCertificate } from "react-icons/gr";

const Sec5 = () => {
    const animationDuration = 1000;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [available, setAvailable] = useState('');
    const [topic, setTopic] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = {
            name,
            email,
            phone,
            available,
            topic
        };
        console.log(formData);
        setName('');
        setAvailable('');
        setTopic('');
        setEmail('');
        setPhone('');
        // Handle form submission (e.g., send data to backend)
    };


    return (
        <div className='sec-5-div'>
            <Container>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2'>
                        <h1 className='first-div-hd' data-aos={'fade-right'} data-aos-duration={animationDuration}>
                            Sign Up Now To Avail The Best Academic Help
                        </h1>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-5'>
                        <div className="form-main-div sec-5-form-div" data-aos={'fade-up'} data-aos-duration={animationDuration}>
                            <h2>
                                <span>
                                    Sign Up Now
                                </span>
                            </h2>
                            <form onSubmit={handleFormSubmit} >
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter Your Name"
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter Your Email"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder="Enter Your Phone Number"
                                    required
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <select
                                    name="available"
                                    value={available}
                                    onChange={e => setAvailable(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>
                                        Is This Number Available On WhatsApp
                                    </option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <input
                                    type="text"
                                    name="topic"
                                    placeholder="Enter Your Topic"
                                    required
                                    value={topic}
                                    onChange={e => setTopic(e.target.value)}
                                />
                                <button type="submit">Get My Discount</button>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                        <div className='row' data-aos={'fade-left'} data-aos-duration={animationDuration}>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 sec-5-right-div'>
                                <HiCurrencyDollar className="sec-5-logo" />
                                <span>
                                    Robust Service
                                </span>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 sec-5-right-div'>
                                <TbHours24 className="sec-5-logo" />
                                <span>
                                    Expert Team
                                </span>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 sec-5-right-div'>
                                <RiSecurePaymentFill className="sec-5-logo" />
                                <span>
                                    Wide Subject Range
                                </span>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 sec-5-right-div'>
                                <GrCertificate className="sec-5-logo" />
                                <span>
                                    Privacy Guaranteed
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Sec5;