import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const HesiExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Streamlined Support for HESI Exam - Facilitate Your Journey to HESI Exam Triumph!"
                    text={`
            Are you seeking assistance with your HESI exam? Indeed, you can enlist an expert to manage your HESI exam and ensure you achieve the necessary score for admission into your desired nursing program. Your search ends here, as we provide excellent HESI exam support services, alleviating your exam-related worries.
        `}
                    list={[
                        'Seamless Services',
                        'Completely Safe and Protected',
                        'Economical Choices',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Continuous Availability'}
                Icon2Text={'Guaranteed Reimbursement'}
                Icon3Text={'Assured Performance'}
                Icon4Text={'Timeliness Guaranteed'}
            />
            <Courses />
            <Service />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default HesiExamHelpPage;