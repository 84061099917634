import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const RealEstateExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Professional Help for Real Estate Exam Readiness by Accredited Experts!"
                    text={`
        Takeexamhelp is widely recognized as a leading online educational support platform, celebrated for its outstanding services designed to ensure your academic journey is a successful one.
    `}
                    list={[
                        'Noteworthy Grades',
                        'Guidance From Experts',
                        'Economical Packages',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Accessible round the clock'}
                Icon2Text={'Expert-Level Service'}
                Icon3Text={<>Budget-Friendly <br /> Price Estimate</>}
                Icon4Text={'Economical Cost Projection'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default RealEstateExamHelpPage;