import { Container } from 'react-bootstrap';
import './style.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    const serviceLinks1 = [
        {
            link: '/online-class-help',
            name: 'online class help',
        },
        {
            link: '/online-course-help',
            name: 'online course help',
        },
        {
            link: '/online-exam-help',
            name: 'online exam help',
        },
        {
            link: '/praxis-exam-help',
            name: 'praxis exam help',
        },
        {
            link: '/comptia-exam-help',
            name: 'comptia exam help',
        },
        {
            link: '/ged-exam-help',
            name: 'ged exam help',
        },
        {
            link: '/gre-exam-help',
            name: 'GRE exam help',
        },
        {
            link: '/pmp-exam-help',
            name: 'PMP exam help',
        },
        {
            link: '/real-estate-exam-help',
            name: 'Real estate exam help',
        },
        {
            link: '/certification-exam-help',
            name: 'certification exam help',
        },
        {
            link: '/hesi-exam-help',
            name: 'hesi exam help',
        },
        {
            link: '/teas-exam-help',
            name: 'teas exam help',
        },
    ]

    const serviceLinks2 = [
        {
            link: '/accounting-class-help',
            name: 'Accounting class Help',
        },
        {
            link: '/algebra-class-help',
            name: 'algebra class help',
        },
        {
            link: '/chemistry-class-help',
            name: 'chemistry class help',
        },
        {
            link: '/finance-class-help',
            name: 'finance class help',
        },
        {
            link: '/economic-class-help',
            name: 'economics class help',
        },
        {
            link: '/law-class-help',
            name: 'law class Help',
        },
        {
            link: '/marketing-class-help',
            name: 'marketing class help',
        },
        {
            link: '/nursing-class-help',
            name: 'nursing class help',
        },
        {
            link: '/statistics-class-help',
            name: 'statistics class help',
        },
        {
            link: '/business-class-help',
            name: 'business class help',
        },
    ]

    return (
        <div className='main-footer-div'>
            <Container>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <p className='footer-hd'>
                            Disclaimer
                        </p>
                        <p className='footer-txt'>
                            Takeexamhelp is the most trusted online academic support platform
                            for its authentic academic services. We strongly discourage the illicit
                            use of our services and products.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <h2 className='ser-hd'>
                            Services
                            <div className='border-div'></div>
                        </h2>
                        <ul className='footer-list'>
                            {serviceLinks1.map((v, i) => {
                                return (
                                    <li key={i}>
                                        <Link to={v.link} className='footer-service-link'>
                                            {v.name}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <h2 className='ser-hd'>
                            Services
                            <div className='border-div'></div>
                        </h2>
                        <ul className='footer-list'>
                            {serviceLinks2.map((v, i) => {
                                return (
                                    <li key={i}>
                                        <Link to={v.link} className='footer-service-link'>
                                            {v.name}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <h2 className='ser-hd'>Usefull links
                            <div className='border-div-2'></div>
                        </h2>
                        <ul className='footer-list'>
                            <li>
                                <Link to={'/contact-us'} className='footer-service-link'>
                                    Contact Us
                                </Link>
                            </li>
                            <li>
                                <Link to={'/terms-and-conditions'} className='footer-service-link'>
                                    terms & conditions
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer