import './style.css';
import Navbar2Comp from '../../Components/Navbar2';
import Banner2 from '../../Sections/Banner2/index.jsx';
import Footer from '../../Components/Footer/index.jsx';
import StickyFooter from '../../Components/StickyFooter/index.jsx';
import { Sitedetails } from '../../Data';
import { Link } from 'react-router-dom';

const ContactUsPage = () => {

    return (
        <>
            <div className="main-nav-banner2-div">
                <Navbar2Comp title={'Contact Us'} />
                <Banner2
                    title={<>Need help with anything?<br />Feel free to contact us around the clock</>}
                    text={
                        <p style={{ fontWeight: '600', fontSize: '18px' }}>
                            You can reach us on Whatsapp at: <Link style={{ textDecoration: 'none' }} to={'tel:' + Sitedetails.phone}>{Sitedetails.phone}</Link>
                            <br />
                            <br />
                            You can get in touch using our email address: <Link style={{ textDecoration: 'none' }} to={'mailto:' + Sitedetails.email}>{Sitedetails.email}</Link>
                        </p>
                    }
                />
            </div>
            <Footer />
            <StickyFooter />
        </>
    )
}

export default ContactUsPage;