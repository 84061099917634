import './style.css';
import NavbarComp from '../../Components/Navbar';
import Banner from '../../Sections/Banner/index.jsx';
import Sec2 from '../../Sections/Sec2/index.jsx';
import Service from '../../Sections/Service/index.jsx';
import Courses from '../../Sections/Courses/index.jsx';
import Sec3 from '../../Sections/Sec3/index.jsx';
import Sec4 from '../../Sections/Sec4/index.jsx';
import Sec5 from '../../Sections/Sec5/index.jsx';
import SubjectSec from '../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../Sections/Testimonial/index.jsx';
import Footer from '../../Components/Footer/index.jsx';
import StickyFooter from '../../Components/StickyFooter/index.jsx';


const HomePage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Receive Professional Help for Your Virtual Courses!"
                    text={`
                            Our platform offers exceptional online class assistance, ensuring you achieve excellence in your studies. Let our experts guide you to academic success.
                        `}
                    list={[
                        'Assured High Grades',
                        "PhD and Master's Degree Professionals",
                        'Cost-Effective Rates',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'24/7 Client Support'}
                Icon2Text={'PhD-level Specialists'}
                Icon3Text={'Wallet-Friendly Deals'}
                Icon4Text={'Repayment Assurance'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default HomePage;