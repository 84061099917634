import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const EconomicClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Master Economics with the Guidance of an Online Educator!"
                    text={`
        Struggling with your economics coursework? Fear not! Access our comprehensive economics tutoring services and excel in your studies. Alternatively, enlist one of our experts to handle your economics assignments for top-notch performance. Our goal is to assist you in achieving excellent grades in economics!
        `}
                    list={[
                        'Assured Academic Success',
                        'Competitive Rates',
                        'Experienced Teachers',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Continuous Availability'}
                Icon2Text={<>Top-notch Service <br /> Excellence</>}
                Icon3Text={'Cost-Effective Pricing'}
                Icon4Text={'Policy for Reimbursements'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default EconomicClassHelpPage;