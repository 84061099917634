import { Container } from 'react-bootstrap';
import './style.css';
import { Carousel } from 'antd';
import Male from '../../Assets/Testmonial/male.png';
import Female from '../../Assets/Testmonial/female.png';

const Testimonial = () => {

    const testData = [
        {
            name: 'John Smith',
            content: "I was struggling to keep up with my coursework, but Takeonlinecourse came to my rescue. Their expert tutors are incredibly knowledgeable and always available to help. Thanks to them, I managed to pass my exams with flying colors!",
            img: Male,
        },
        {
            name: 'Emma Thompson',
            content: "Takeonlinecourse has been a lifesaver for me. Their prompt and professional assistance allowed me to focus on other important aspects of my life while still excelling in my studies. I highly recommend their services to anyone in need of academic support.",
            img: Female,
        },
        {
            name: 'Mark Johnson',
            content: "I had my doubts about online academic platforms, but Takeonlinecourse exceeded my expectations. The personalized support I received was outstanding, and their flexible policies made it easy for me to manage my time effectively. I'm truly grateful for their help.",
            img: Male,
        },
        {
            name: 'Sophia Williams',
            content: "The team at Takeonlinecourse is exceptional. They provided me with detailed explanations and guidance, making complex topics easy to understand. Their support played a significant role in my academic success. I can't thank them enough!",
            img: Female,
        },
        {
            name: 'Alex Davis',
            content: "Takeonlinecourse restored my confidence in my academic abilities. Their dedicated team of experts offered invaluable assistance, helping me achieve my academic goals. I highly recommend their services to anyone seeking reliable and effective academic support.",
            img: Male,
        },
    ];


    return (
        <div className='test-div'>
            <Container className='test-container'>
                <Carousel autoplay autoplaySpeed={3000} className='main-carousel-div' dots={false}>
                    {testData.map((v, i) => {
                        return (
                            <div className='carousel-div' key={i}>
                                <div className='icon-name-div'>
                                    <img src={v.img} alt="" />
                                    <p>{v.name}</p>
                                </div>
                                <p>
                                    {v.content}
                                </p>
                            </div>
                        )
                    })}

                </Carousel>
            </Container>
        </div>
    )
}

export default Testimonial;