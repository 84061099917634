import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const OnlineCourseHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Enhance your web-based course with our assistance!"
                    text={`
        Takeexamhelp provides impeccable online course aid from PhD experts. We guarantee timely submission, conduct plagiarism assessments, and offer robust assistance to boost your academic performance.
    `}
                    list={[
                        'Specialists Focused on Specific Subjects',
                        'Money-back Guarantee',
                        'Unlimited Revisions',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={<>100% Plagiarism-Free <br /> Work</>}
                Icon2Text={'Punctual Submission'}
                Icon3Text={"Experts at the Doctorate level"}
                Icon4Text={'Refund Policy'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default OnlineCourseHelpPage;