import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const GreExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Help with the GED Test to Achieve Your Dream College Placement!"
                    text={`
                            Studying for the GED covers various topics, which can be daunting for numerous students. If it feels too much to handle, allow us to take your GED exam on your behalf and begin living the life you’ve always envisioned.
                        `}
                    list={[
                        'Guaranteed Top Scores',
                        'Highly Qualified Team',
                        'Exceptionally Affordable Packages',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Continuous Availability'}
                Icon2Text={'Advanced Academic Assistance'}
                Icon3Text={'Economical Plans'}
                Icon4Text={'Guaranteed Return'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default GreExamHelpPage;