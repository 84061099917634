import './style.css';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Sitedetails } from "../../Data";
import { AiOutlineMessage } from "react-icons/ai";


const StickyFooter = () => {
    console.log(Sitedetails.phone.replaceAll(" ", "").slice('1', Sitedetails.phone.length))
    return (
        <div className='stiky-footer-div'>
            <Link className="button-fixed me-2" to={`javascript:void(Tawk_API.toggle())`}>
                <AiOutlineMessage className='me-1' />
                Get A Quote
            </Link>
            <Link target='_blank' className="button-fixed" to={`https://api.whatsapp.com/send/?phone=%2B${Sitedetails.phone.replaceAll(" ", "").slice('1', Sitedetails.phone.length)}&text&type=phone_number&app_absent=0`}>
                <FaWhatsapp className='me-1' />
                WhatsApp Now
            </Link>
            <Link target='_blank' className='wa-btn' to={`https://api.whatsapp.com/send/?phone=%2B${Sitedetails.phone.replaceAll(" ", "").slice('1', Sitedetails.phone.length)}&text&type=phone_number&app_absent=0`}>
                <FaWhatsapp />
            </Link>
        </div>
    )
}

export default StickyFooter;