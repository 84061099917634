import { Container } from 'react-bootstrap';
import './style.css';
import { LuNewspaper } from "react-icons/lu";
import { TbHomeEdit } from "react-icons/tb";
import {
    FaSunPlantWilt, FaBusinessTime, FaComputer, FaGoogleScholar, FaDna, FaEarthAfrica, FaPersonSnowboarding,
    FaUserNurse
} from "react-icons/fa6";
import { IoIosColorPalette } from "react-icons/io";
import { SlChemistry } from "react-icons/sl";
import { GiSatelliteCommunication, GiCoins, GiPirateCoat, GiMechanicGarage } from "react-icons/gi";
import { PiToothLight, PiFilmReelBold, PiPottedPlantFill, PiMathOperationsFill, PiFinnTheHumanFill } from "react-icons/pi";
import { RiEnglishInput } from "react-icons/ri";
import { BsFillCameraFill, BsLuggageFill } from "react-icons/bs";
import { GoLaw } from "react-icons/go";
import { SiGooglemarketingplatform } from "react-icons/si";
import { AiFillMedicineBox } from "react-icons/ai";
import { LuMoonStar } from "react-icons/lu";
import { TbBuildingBank } from "react-icons/tb";
import { MdOutlineSocialDistance } from "react-icons/md";


const SubjectSec = () => {

    const animationName = 'zoom-in';
    const animationDuration = 600;

    const subList = [
        {
            Icon: <LuNewspaper className='subjects-icon' />,
            first: 'Accounting &',
            second: 'Finance',
        },
        {
            Icon: <FaSunPlantWilt className='subjects-icon' />,
            first: 'Agriculture &',
            second: 'Forestry',
        },
        {
            Icon: <TbHomeEdit className='subjects-icon' />,
            first: 'Architecture'
        },
        {
            Icon: <IoIosColorPalette className='subjects-icon' />,
            first: 'Art &',
            second: 'Design',
        },
        {
            Icon: <FaDna className='subjects-icon' />,
            first: 'Biological',
            second: 'Sciences',
        },
        {
            Icon: <FaBusinessTime className='subjects-icon' />,
            first: 'Business',
            second: 'Management',
        },
        {
            Icon: <SlChemistry className='subjects-icon' />,
            first: 'Chemistry'
        },
        {
            Icon: <GiSatelliteCommunication className='subjects-icon' />,
            first: 'Communication',
            second: '& Media Studies',
        },
        {
            Icon: <FaComputer className='subjects-icon' />,
            first: 'Computer',
            second: 'Science',
        },
        {
            Icon: <PiToothLight className='subjects-icon' />,
            first: 'Dentistry'
        },
        {
            Icon: <PiFilmReelBold className='subjects-icon' />,
            first: 'Drama, Dance',
            second: '& Cinematics',
        },
        {
            Icon: <PiFinnTheHumanFill className='subjects-icon' />,
            first: 'Sociology',
        },
        {
            Icon: <GiCoins className='subjects-icon' />,
            first: 'Economics'
        },
        {
            Icon: <FaGoogleScholar className='subjects-icon' />,
            first: 'Education'
        },
        {
            Icon: <RiEnglishInput className='subjects-icon' />,
            first: 'English',
            second: 'Fashion',
        },
        {
            Icon: <GiPirateCoat className='subjects-icon' />,
            first: 'Film Making',
        },
        {
            Icon: <BsFillCameraFill className='subjects-icon' />,
            first: 'Geography &',
            second: 'Environmental',
        },
        {
            Icon: <FaEarthAfrica className='subjects-icon' />,
            first: 'Geology'
        },
        {
            Icon: <PiPottedPlantFill className='subjects-icon' />,
            first: 'Hospitality &',
            second: 'Tourism',
        },
        {
            Icon: <BsLuggageFill className='subjects-icon' />,
            first: 'History'
        },
        {
            Icon: <GoLaw className='subjects-icon' />,
            first: 'Law',
        },
        {
            Icon: <FaPersonSnowboarding className='subjects-icon' />,
            first: 'Hospitality',
            second: 'Tourism',
        },
        {
            Icon: <SiGooglemarketingplatform className='subjects-icon' />,
            first: 'Marketing',
        },
        {
            Icon: <PiMathOperationsFill className='subjects-icon' />,
            first: 'Mathematics',
        },
        {
            Icon: <GiMechanicGarage className='subjects-icon' />,
            first: 'Mechanical',
            second: 'Engineering',
        },
        {
            Icon: <AiFillMedicineBox className='subjects-icon' />,
            first: 'Medicine',
        },
        {
            Icon: <FaUserNurse className='subjects-icon' />,
            first: 'Nursing',
        },
        {
            Icon: <PiFinnTheHumanFill className='subjects-icon' />,
            first: 'Philosophy',
        },
        {
            Icon: <LuMoonStar className='subjects-icon' />,
            first: 'Physics &',
            second: 'Astronomy',
        },
        {
            Icon: <TbBuildingBank className='subjects-icon' />,
            first: 'Politics',
        },
        {
            Icon: <PiFinnTheHumanFill className='subjects-icon' />,
            first: 'Psychology',
        },
        {
            Icon: <MdOutlineSocialDistance className='subjects-icon' />,
            first: 'Theology',
        },
    ]

    return (
        <div className='subject-div'>
            <Container className='subject-container'>
                <h1>Subjects We Offer</h1>
                <p>
                    Over 1500 subject specialists are all set to help
                    you across all disciplines
                </p>
                <div className='row'>
                    {subList.map((v, i) => {
                        return (
                            <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 d-flex mb-3 sub-main-div' key={i} data-aos={animationName} data-aos-duration={animationDuration}>
                                <div className='sub-icon'>
                                    {v.Icon}
                                </div>
                                <div className='subject-text'>
                                    {v.first}
                                    <br />
                                    {v.second}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </div>
    )
}

export default SubjectSec;