import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const LawClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Best Time to Seek Help with Enrolling in My Internet Law Program!"
                    text={`
        For students worried about effectively finishing their web-based course, the best decision is to recruit a professional to handle my internet law program. Here's the process: Click the ENROLL NOW button to get in touch with our team of outstanding online course assistants. From there, you can easily engage someone to handle my internet law program for me.
    `}
                    list={[
                        'Licensed Legal Specialists',
                        'Safe and Dependable Payment Method',
                        'Rigorous Security Measures',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Fully Confidential'}
                Icon2Text={'Engaged Involvement'}
                Icon3Text={'Proficient Instructors'}
                Icon4Text={'Assured High Achievements'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default LawClassHelpPage;