import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const PmpExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Achieve Your PMP Certification Successfully with Our PMP Exam Support"
                    text={`
        If you're striving to harmonize your resume with your dreams of becoming a project manager, the best strategy would be to engage one of our experts to manage my PMP exam for you. Here, you'll find premier PMP exam assistance providers.
        `}
                    list={[
                        'Guaranteed Scores',
                        'Premium Services',
                        'Tailored for Students',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={<>Round-The-Clock <br /> Service</>}
                Icon2Text={'Ph.D. Level Help'}
                Icon3Text={'Economical Pricing Plan'}
                Icon4Text={'Refund Guarantee'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default PmpExamHelpPage;