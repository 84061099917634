import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const OnlineExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Enhance Your Exam Achievement Using Online Assistance!"
                    text={`
        Are you in need of aid for my exam and obtaining online exam assistance? At Takeexamhelp, we offer the support you need. We are dedicated to delivering our utmost to guarantee your triumph.
        `}
                    list={[
                        'Seasoned Examination Experts',
                        'Completely encrypted information',
                        'Guaranteed A or A+ Results',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Certified Exam Professionals'}
                Icon2Text={<>Continuous Vigilant <br /> Reaction</>}
                Icon3Text={"Assured 'A' Grade"}
                Icon4Text={'Complete Encryption'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default OnlineExamHelpPage;