import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const StatisticsClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Achieve Success: Enroll in My Statistics Course!"
                    text={`
      Balancing complex subjects alongside your current commitments can overwhelm your capacity. That's why we offer exceptional support for statistics courses to assist individuals prepared to make a move. If you're facing a similar hurdle, consider contacting us with the query, 'Could you enroll me in my statistics course?'
      `}
                    list={[
                        'Accredited Statistics Professionals',
                        'Safe and Dependable Payment System',
                        'Rigorous Security Measures',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Fully Confidential'}
                Icon2Text={'Engaged Involvement'}
                Icon3Text={'Proficient Instructors'}
                Icon4Text={'Assured High Achievements'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default StatisticsClassHelpPage;