import { Link } from 'react-router-dom'
import './style.css'
const Button = (props) => {
    const {
        title,
        link,
        compclass
    } = props
    return (
        link ? <Link to={link}>
            <button className={'chat-btn ' + compclass } {...props}>{title}</button>
        </Link> : <button className={'chat-btn ' + compclass} {...props}>{title}</button>
    )
}

export default Button;