import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const ChemistryClassHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Get Expert Help for My Chemistry Course!"
                    text={`
        Undoubtedly, chemistry presents formidable hurdles, making it advantageous to consider getting support for effective management. That's why we provide the opportunity to enlist assistance for your chemistry course, easing a significant portion of your academic responsibilities. Just click the JOIN NOW button, and we'll promptly get in touch to discuss further details.
        `}
                    list={[
                        'Skilled English Experts',
                        'Guaranteed Success',
                        'Cost-Effective Rates',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={<>Round-The-Clock <br /> Service</>}
                Icon2Text={'Talented Team'}
                Icon3Text={'Economical Pricing Plan'}
                Icon4Text={'Refund Guarantee'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default ChemistryClassHelpPage;