import { Container } from 'react-bootstrap'
import './style.css';
import { Sitedetails } from '../../Data';
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail, IoMdMenu } from "react-icons/io";
import { Link } from 'react-router-dom';
import Button from '../Button/index.jsx';
import { useState } from 'react';
import Logo from '../../Assets/takexamhelp(removed-bg).png';

const NavbarComp = () => {

    const [showNav, setShowNav] = useState(false);

    const pagesLinks = [
        {
            name: 'home',
            link: '/'
        },
        {
            name: 'blog',
            link: '/blog'
        },
        {
            name: 'terms and conditions',
            link: '/terms-and-conditions'
        },
        {
            name: 'contact us',
            link: '/contact-us'
        }
    ]

    return (
        <div className='main-navbar-div'>
            <div className="main-nav">
                <Container className='nav-container'>
                    <div className='d-flex'>
                        Empowering Minds, Enriching Futures: Learn Anytime, Anywhere!
                    </div>
                    <div className='d-flex'>
                        <Link className='nav-link' to={'tel:' + Sitedetails.phone}>
                            <FaPhoneAlt className='me-2' />
                            {Sitedetails.phone}
                        </Link>
                        <Link className='nav-link nav-link-1' to={'tel:' + Sitedetails.email}>
                            <IoIosMail className='me-2' />
                            {Sitedetails.email}
                        </Link>
                    </div>
                </Container>
            </div>
            <div className="main-nav-2">
                <Container className='nav-container-2'>
                    <div className='logo-div'>
                        <Link to={'/'}>
                            <img src={Logo} className='logo-img' alt="Logo" />
                        </Link>
                    </div>
                    <div className='nav-links-div ms-auto me-auto'>
                        {pagesLinks.map((v, i) => {
                            return (
                                <Link className='nav-tab-link me-3' key={i} to={v.link}>{v.name}</Link>
                            )
                        })}
                    </div>
                    <Button compclass='nav-chat-btn' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                </Container>
            </div>
            <div className='nav-responsive-main'>
                <Container className='d-flex '>
                    <div className='res-logo-div'>
                        <Link to={'/'}>
                            <img src={Logo} className='res-logo-img' alt="Logo" />
                        </Link>
                    </div>
                    <div className='hum-burger-div'>
                        <button
                            className='hum-burger-btn'
                            onClick={() => setShowNav(!showNav)}
                        >
                            <IoMdMenu />
                        </button>
                    </div>
                </Container>
                {showNav &&
                    <div className='res-nav-links-div'>
                        <Container className='res-nav-conainer'>
                            {pagesLinks.map((v, i) => {
                                return (
                                    <Link className='nav-tab-link nav-link-res' key={i} to={v.link}>{v.name}</Link>
                                )
                            })}
                            <Button compclass='nav-chat-btn' link={'javascript:void(Tawk_API.toggle())'} title="Live Chat"></Button>
                        </Container>
                    </div>
                }
            </div>
        </div>
    )
}

export default NavbarComp