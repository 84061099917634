import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const ComptiaExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="CompTIA Exam Assistance from Field Experts!"
                    text={`
        Don't be intimidated by the CompTIA exam—our team is ready to assist you. Contact our specialists, and we'll manage your CompTIA exam on your behalf. We're always prepared to go above and beyond to ensure your success!
        `}
                    list={[
                        'Guaranteed Results',
                        'Top-tier Services',
                        'Focused on Students',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={<>Round-The-Clock <br /> Service</>}
                Icon2Text={'Ph.D. Level Help'}
                Icon3Text={'Economical Pricing Plan'}
                Icon4Text={'Refund Guarantee'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default ComptiaExamHelpPage;