import { Container } from 'react-bootstrap';
import './style.css';
import Sec4Img from '../../Assets/Sec4/s4_1.png';


const Sec4 = () => {
    return (
        <div className='sec-4-div'>
            <Container>
                <h1 className='sec-4-hd'>
                    Simple Steps To Academic Success
                </h1>
                <img src={Sec4Img} alt="img" className='sec-4-img' />
            </Container>
        </div>
    )
}

export default Sec4;