import "./style.css"
import { Container } from "react-bootstrap";
import Button from '../../Components/Button/index.jsx';
import { Sitedetails } from "../../Data";
import { useState } from "react";
import { TypeAnimation } from 'react-type-animation';


const Banner = (props) => {
    const animationDuration = 1000;

    const {
        title,
        text,
        list,
        centeredText,
        bannerHide,
        blogBanner
    } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [available, setAvailable] = useState('');
    const [topic, setTopic] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = {
            name,
            email,
            phone,
            available,
            topic
        };
        console.log(formData);
        setName('');
        setAvailable('');
        setTopic('');
        setEmail('');
        setPhone('');
        // Handle form submission (e.g., send data to backend)
    };

    return (
        <>
            {
                blogBanner && <div className="main-banner-div-blog">
                    <TypeAnimation
                        sequence={[
                            `${centeredText}`,
                        ]}
                        wrapper="h2"
                        speed={50}
                        repeat={0}
                        cursor={false}
                        className='center-text'
                    />

                </div>
            }
            {
                !bannerHide && <div className="main-banner-div" >
                    <Container className="banner-container">
                        <div className="text-div" data-aos={'fade-right'} data-aos-duration={animationDuration}>
                            <h1>
                                {title}
                            </h1>
                            <p>
                                {text}
                            </p>
                            <ul>
                                {list && list.map((v, i) => {
                                    return (
                                        <li key={i}>{v}</li>
                                    )
                                })}
                            </ul>
                            <Button compclass='banner-wa-btn mt-1' title={'WhatsApp Now'} target='_blank' link={`https://api.whatsapp.com/send/?phone=%2B92${Sitedetails.phone}&text&type=phone_number&app_absent=0`} />
                            <span className="ms-1 me-1"></span>
                            <Button compclass='banner-wa-btn mt-1' title={'Connect With Expert'} target='_blank' link={`javascript:void(Tawk_API.toggle())`} />
                        </div>
                        <div className="form-main-div" data-aos={'fade-left'} data-aos-duration={animationDuration}>
                            <h2>
                                <span>
                                    Get 40% Discount
                                </span>
                            </h2>
                            <form onSubmit={handleFormSubmit}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter Your Name"
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter Your Email"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder="Enter Your Phone Number"
                                    required
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <select
                                    name="available"
                                    value={available}
                                    onChange={e => setAvailable(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>
                                        Is This Number Available On WhatsApp
                                    </option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <input
                                    type="text"
                                    name="topic"
                                    placeholder="Enter Your Topic"
                                    required
                                    value={topic}
                                    onChange={e => setTopic(e.target.value)}
                                />
                                <button type="submit">Get Offer Now</button>
                            </form>
                        </div>

                    </Container>
                </div>
            }
        </>
    )
}

export default Banner;