import "./style.css"
import { Container } from "react-bootstrap";
import { useState } from "react";


const Banner = (props) => {

    const animationDuration = 600;

    const {
        title,
        text,
    } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [available, setAvailable] = useState('');
    const [topic, setTopic] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = {
            name,
            email,
            phone,
            available,
            topic
        };
        console.log(formData);
        setName('');
        setAvailable('');
        setTopic('');
        setEmail('');
        setPhone('');
        // Handle form submission (e.g., send data to backend)
    };

    return (
        <div className="main-banner2-div">
            <Container className="banner-container">
                <div className="banner2-text-div" data-aos={'fade-right'} data-aos-duration={animationDuration}>
                    <h1>
                        {title}
                    </h1>
                    {text}
                </div>
                <div className="form-main-div banner2-form-main-div" data-aos={'fade-left'} data-aos-duration={animationDuration}>
                    <h2>
                        <span>
                            Order Right Away To Score Top Grades
                        </span>
                    </h2>
                    <form onSubmit={handleFormSubmit}>
                        <label htmlFor="name">
                            Your Name <sup>*</sup>
                        </label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            required
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <label htmlFor="email">
                            Your Email <sup>*</sup>
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter Your Email"
                            required
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <label htmlFor="phone">
                            Your Phone <sup>*</sup>
                        </label>
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Enter Your Phone Number"
                            required
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                        <label htmlFor="available">
                            Availability On WhatsApp <sup>*</sup>
                        </label>
                        <select
                            name="available"
                            value={available}
                            onChange={e => setAvailable(e.target.value)}
                            required
                        >
                            <option value="" disabled>
                                Is This Number Available On WhatsApp
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <label htmlFor="message">
                            Message <sup>*</sup>
                        </label>
                        <textarea
                            type="text"
                            name="topic"
                            required
                            value={topic}
                            onChange={e => setTopic(e.target.value)}
                            className="message-input"
                        />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </Container>
        </div>
    )
}

export default Banner;