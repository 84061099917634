import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const BusinessClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Hire an expert to manage my virtual business course for me!"
                    text={`
      Need help managing your business course? Your search ends here! We offer excellent support for all aspects of business courses. Our team includes over a hundred subject experts who excel as online class assistants. Get in touch now to get help with taking my online business course for me.
      `}
                    list={[
                        'Expert Advice',
                        'Guaranteed Grades',
                        'Cost-effective Rates',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Fully Confidential'}
                Icon2Text={'Engaged Involvement'}
                Icon3Text={'Proficient Instructors'}
                Icon4Text={'Assured High Achievements'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default BusinessClassHelpPage;