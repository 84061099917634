import 'bootstrap/dist/css/bootstrap.min.css';
import RotesPages from "./route";


function App() {
  return (
    <RotesPages />
  );
}

export default App;
