import { Container } from 'react-bootstrap';
import './style.css';
import img from '../../Assets/Sec-3/s4_1.webp';

const Sec3 = () => {
    return (
        <div className='sec-3-div'>
            <Container>
                <div className='row'>
                    <div className='col-xl-8 col-lg-8 col-md-9 col-sm-12 p-1 row sub-container-div-1'>
                        <div className='col-lg-3 col-xl-3 col-md-3 col-sm-3 sec-5-img-main'>
                            <img src={img} alt="" />
                        </div>
                        <div className='col-lg-9 col-xl-9 col-md-9 col-sm-9 p1 row sec-5-text-div'>
                            <h1>
                                Trusted By
                                35,000+ Students
                            </h1>
                            <p>
                                When faced with the daunting clouds of fear and the specter of failure, Takeonlinecourse stands ready with unwavering dedication. We deeply understand the challenges students encounter on their academic journeys. Whether you seek assistance to pay someone to take my online exam or hire someone to take my class exam, we are here to support you. We also offer comprehensive online homework help and online course assistance.
                                <br />
                                <br />
                                Takeonlinecourse is renowned for its genuine, dependable, and affordable academic services, trusted by students from prestigious universities worldwide. Students rely on our stringent privacy policies and expert team to achieve their academic aspirations. Every day, we elevate the grades of countless students across various fields of study.
                            </p>

                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-3 col-sm-12 sub-container-di2'>
                        <div className='num-div-main'>
                            <h1>
                                35000+
                            </h1>
                            <p>
                                Students
                            </p>
                        </div>
                        <div className='num-div-main'>
                            <h1>
                                1500+
                            </h1>
                            <p>
                                Experts
                            </p>
                        </div>
                        <div className='num-div-main'>
                            <h1>
                                33000+
                            </h1>
                            <p>
                                A-grades
                            </p>
                        </div>
                        <div className='num-div-main'>
                            <h1>
                                18400+
                            </h1>
                            <p>
                                Orders
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Sec3;