import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const PraxisExamHelpPage = () => {

    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Tips for Excelling in the Praxis Exam: Key Strategies for Outstanding Performance!"
                    text={`
            Are you nervous about your upcoming praxis exam on the web because you haven't reached out to us yet? There's no need to delay any further! Contact us immediately to have our specialists handle your praxis exam for you. Our experts will reach out to you without delay!
        `}
                    list={[
                        'top score',
                        'Outstanding Service',
                        'Economical Choices',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Continuous Availability'}
                Icon2Text={'Advanced Academic Assistance'}
                Icon3Text={'Economical Plans'}
                Icon4Text={'Guaranteed Return'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default PraxisExamHelpPage;