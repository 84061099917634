import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const MarketingClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Engage a Professional for Managing My Marketing Course!"
                    text={`
    Thinking about enlisting help to manage my marketing course? Look no further for the most convenient solution. We offer excellent support for all aspects of marketing coursework. Say goodbye to your online class worries. Contact us now and enlist the expertise of our marketing specialists to handle my online marketing course.
  `}
                    list={[
                        'Skilled in English',
                        'Assured Academic Performance',
                        'Affordable Pricing',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Continuous Availability'}
                Icon2Text={<>Top-notch Service <br /> Excellence</>}
                Icon3Text={'Cost-Effective Pricing'}
                Icon4Text={'Policy for Reimbursements'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default MarketingClassHelpPage;