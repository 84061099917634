import './style.css';
import NavbarComp from '../../Components/Navbar';
import Banner from '../../Sections/Banner/index.jsx';
import StickyFooter from '../../Components/StickyFooter/index.jsx';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer/index.jsx';
//Images 
import Best_Exam_Help_Business from '../../Assets/Blog/Best_Exam_Help_Business.png';
import Best_Examination from '../../Assets/Blog/Best_Examination.png';
import Best_In_Exam from '../../Assets/Blog/Best_In_Exam.png';
import Chemistry_Exams_Help from '../../Assets/Blog/Chemistry_Exams_Help.png';
import Exam_For_IT_Students from '../../Assets/Blog/Exam_For_IT_Students.png';
import Exam_To_Know_Your_Course from '../../Assets/Blog/Exam_To_Know_Your_Course.png';
import Examination_Course from '../../Assets/Blog/Examination_Course.png';
import Exams_For_Masters from '../../Assets/Blog/Exams_For_Masters.png';
import Exams_You_Can_Gave_After_Graduation from '../../Assets/Blog/Exams_You_Can_Gave_After_Graduation.png';
import GED_Exams_Help from '../../Assets/Blog/GED_Exams_Help.png';
import Give_Me_Your_Homework from '../../Assets/Blog/Give_Me_Your_Homework.png';
import Graduation_Level_Exam from '../../Assets/Blog/Graduation_Level_Exam.png';
import I_Took_My_Exam from '../../Assets/Blog/I_Took_My_Exam.png';
import My_Best_Grades from '../../Assets/Blog/My_Best_Grades.png';
import My_Online_Exam from '../../Assets/Blog/My_Online_Exam.png';
import Online_Comprehensive_Exam from '../../Assets/Blog/Online_Comprehensive_Exam.png';
import Online_Exam_Course from '../../Assets/Blog/Online_Exam_Course.png';
import Pass_A_Course from '../../Assets/Blog/Pass_A_Course.png';
import Pass_The_Class from '../../Assets/Blog/Pass_The_Class.png';
import Take_Comptia_Exam from '../../Assets/Blog/Take_Comptia_Exam.png';
import Take_Examination from '../../Assets/Blog/Take_Examination.png';
import Take_My_Exam_Online from '../../Assets/Blog/Take_My_Exam_Online.png';
import Take_My_GED_Test_Online from '../../Assets/Blog/Take_My_GED_Test_Online.png';
import Take_Your_Online_Classes from '../../Assets/Blog/Take_Your_Online_Classes.png';
import Taking_Comptia_Online_Exam from '../../Assets/Blog/Taking_Comptia_Online_Exam.png';
import The_Best_Grades from '../../Assets/Blog/The_Best_Grades.png';
import The_Exam_Online from '../../Assets/Blog/The_Exam_Online.png';
import Took_An_Exam from '../../Assets/Blog/Took_An_Exam.png';
import You_Will_Pass_Your_Exam from '../../Assets/Blog/You_Will_Pass_Your_Exam.png';

const BlogPage = () => {

    const animationName = 'zoom-in';
    const animationDuration = 600;

    const blogCardData = [
        {
            heading: 'Chemistry Exams Assistance',
            text: 'Get expert help and personalized study materials for your chemistry exam with Takeexamhelp.com.',
            imgLink: Chemistry_Exams_Help,
            pageLink: '/SpecificeService/chemistry-exam-help'
        },
        {
            heading: 'GED Exams Support',
            text: 'Boost your GED scores with guidance and resources from Takeexamhelp.com.',
            imgLink: GED_Exams_Help,
            pageLink: '/SpecificeService/ged-exam-help'
        },
        {
            heading: 'Online Course Support',
            text: 'Receive tailored assistance and expert tutoring for success in your online courses at Takeexamhelp.com.',
            imgLink: Take_Your_Online_Classes,
            pageLink: '/SpecificeService/take-your-online-classes'
        },
        {
            heading: 'GED Test Online Help',
            text: 'Get expert support for managing online GED exams with Takeexamhelp.com.',
            imgLink: Take_My_GED_Test_Online,
            pageLink: '/SpecificeService/take-my-ged-test-online'
        },
        {
            heading: 'Online Exam Assistance',
            text: 'Excel in your online exams with expert help from Takeexamhelp.com.',
            imgLink: Take_My_Exam_Online,
            pageLink: '/SpecificeService/take-my-exam-online'
        },
        {
            heading: 'Online Exam Courses',
            text: 'Achieve academic success in online exam courses with Takeexamhelp.com.',
            imgLink: Online_Exam_Course,
            pageLink: '/SpecificeService/online-exam-course'
        },
        {
            heading: 'Achieve Top Grades',
            text: 'Reach academic excellence with professional guidance from Takeexamhelp.com.',
            imgLink: The_Best_Grades,
            pageLink: '/SpecificeService/the-best-grades'
        },
        {
            heading: 'Online Exam Preparation',
            text: 'Prepare effectively for online exams with tips and benefits from Takeexamhelp.com.',
            imgLink: The_Exam_Online,
            pageLink: '/SpecificeService/the-exam-online'
        },
        {
            heading: 'CompTIA Exam Preparation',
            text: 'Get ready for CompTIA certification and enhance your IT career with Takeexamhelp.com.',
            imgLink: Take_Comptia_Exam,
            pageLink: '/SpecificeService/take-comptia-exam'
        },
        {
            heading: 'Top Exam Help Services',
            text: 'Excel academically with top-tier tutors and comprehensive services at Takeexamhelp.com.',
            imgLink: Best_Exam_Help_Business,
            pageLink: '/SpecificeService/best-exam-help-business'
        },
        {
            heading: "Master's Exam Preparation",
            text: 'Get guidance and insights for postgraduate entry exams with Takeexamhelp.com.',
            imgLink: Exams_For_Masters,
            pageLink: '/SpecificeService/exam-for-masters'
        },
        {
            "heading": "Graduation Level Exam Preparation",
            "text": "Get customized study plans and expert guidance for acing your graduation exams with Takeexamhelp.com.",
            "imgLink": Graduation_Level_Exam,
            "pageLink": "/SpecificeService/graduation-level-exam"
        },
        {
            "heading": "Post-Graduation Exam Preparation",
            "text": "Prepare for exams like GRE, GMAT, and LSAT with specialized support from Takeexamhelp.com.",
            "imgLink": Exams_You_Can_Gave_After_Graduation,
            "pageLink": "/SpecificeService/exam-you-can-give-after-graduation"
        },
        {
            "heading": "Take Your Exams Online",
            "text": "Enjoy a user-friendly interface and secure online exam experience with Takeexamhelp.com.",
            "imgLink": Take_Examination,
            "pageLink": "/SpecificeService/take-examination"
        },
        {
            "heading": "Online Comprehensive Exams",
            "text": "Discover how online comprehensive exams are transforming assessment with Takeexamhelp.com.",
            "imgLink": Online_Comprehensive_Exam,
            "pageLink": "/SpecificeService/online-comprehensive-exam"
        },
        {
            "heading": "Prepare for Your Online Exam",
            "text": "Use practice tests and detailed instructions from Takeexamhelp.com to ace your online exams.",
            "imgLink": I_Took_My_Exam,
            "pageLink": "/SpecificeService/i-took-my-exam"
        },
        {
            "heading": "Ensure Exam Success",
            "text": "Boost your chances of passing with personalized tutoring and support from Takeexamhelp.com.",
            "imgLink": You_Will_Pass_Your_Exam,
            "pageLink": "/SpecificeService/you-will-pass-your-exam"
        },
        {
            "heading": "Course Mastery",
            "text": "Achieve course success with practice exams, study guides, and tutoring from Takeexamhelp.com.",
            "imgLink": Pass_A_Course,
            "pageLink": "/SpecificeService/pass-a-course"
        },
        {
            "heading": "Smooth Online Exam Experience",
            "text": "Take your online exams smoothly with advanced proctoring and expert guidance from Takeexamhelp.com.",
            "imgLink": My_Online_Exam,
            "pageLink": "/SpecificeService/my-online-exam"
        },
        {
            "heading": "Online CompTIA Exams",
            "text": "Enjoy the convenience of online CompTIA exams with flexible scheduling and instant feedback.",
            "imgLink": Taking_Comptia_Online_Exam,
            "pageLink": "/SpecificeService/taking-my-comptia-online-exam"
        },
        {
            "heading": "Excel in Online Exams",
            "text": "Achieve excellence in online exams with flexibility and instant feedback from Takeexamhelp.com.",
            "imgLink": Best_In_Exam,
            "pageLink": "/SpecificeService/best-in-exam"
        },
        {
            "heading": "Course Suitability Exam",
            "text": "Get personalized course recommendations that match your strengths and career goals with Takeexamhelp.com.",
            "imgLink": Exam_To_Know_Your_Course,
            "pageLink": "/SpecificeService/exam-to-know-your-course"
        },
        {
            heading: 'IT Student Exam Preparation',
            text: "Efficiently prepare for IT exams with strategic study approaches and disciplined habits at Takeexamhelp.com.",
            imgLink: Exam_For_IT_Students,
            pageLink: '/SpecificeService/exam-for-it-students'
        },
        {
            heading: 'Exam Preparation Course',
            text: "Boost your grades with expert exam preparation and personalized learning at Takeexamhelp.com.",
            imgLink: Examination_Course,
            pageLink: '/SpecificeService/examination-course'
        },
        {
            heading: 'Achieve Top Grades',
            text: "Reach top grades with customized support and resources from Takeexamhelp.com.",
            imgLink: My_Best_Grades,
            pageLink: '/SpecificeService/my-best-grades'
        },
        {
            heading: 'Premier Exam Strategies',
            text: "Excel in exams with Takeexamhelp.com's tailored assessments and strategies.",
            imgLink: Best_Examination,
            pageLink: '/SpecificeService/best-exmination'
        },
        {
            heading: 'Class Success Tips',
            text: "Get insights into exam formats, strategies, and tips with Pro Tips from Takeexamhelp.com.",
            imgLink: Pass_The_Class,
            pageLink: '/SpecificeService/pass-the-class'
        },
        // {
        //     heading: 'Exam Success Pathway',
        //     text: "Rely on Takeexamhelp.com for crucial insights and a clear pathway to exam success.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/go-to-my-exam'
        // },
        {
            heading: 'Post-Graduate Exam Prep',
            text: "Prepare for key post-graduate exams like GRE, GMAT, and CAT with Takeexamhelp.com.",
            imgLink: Took_An_Exam,
            pageLink: '/SpecificeService/took-an-exam'
        },
        // {
        //     heading: 'Top Post-Graduation Exams',
        //     text: "Discover key post-graduate exams like GRE, GMAT, and CAT for career advancement or further education.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/top-exam-after-graduation'
        // },
        {
            heading: 'Homework Ethics',
            text: "Explore the ethical considerations of 'Give Me Your Homework' services with Takeexamhelp.com.",
            imgLink: Give_Me_Your_Homework,
            pageLink: '/SpecificeService/give-me-your-home-work'
        },
        // {
        //     heading: 'CompTIA Exam Guide',
        //     text: "Navigate your path to success in the tech industry with Takeexamhelp.com's guide to CompTIA exams.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/compia-my-exam'
        // },
        // {
        //     heading: 'Seamless Online Exams',
        //     text: "Trust Takeexamhelp.com for seamless, secure, and supportive online exam experiences.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/my-examination'
        // },
        // {
        //     heading: 'Ace Your Exams',
        //     text: "Excel in exams with efficient study methods and confidence-building at Takeexamhelp.com.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/i-will-pass-my-exam'
        // },
        // {
        //     heading: 'Conquer Online Exams',
        //     text: "Navigate online exams successfully with Takeexamhelp.com's comprehensive handbook for preparation.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/my-exam-online'
        // },
        // {
        //     heading: 'Ultimate Exam Review',
        //     text: "Optimize your exam preparation! Plan time management, organize study materials, and enhance your chances of success. Improve memory retention and reduce exam stress!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/best-way-to-review-for-exam'
        // },
        // {
        //     heading: 'Exam Success Course',
        //     text: "Achieve exam success! Learn about study strategies, effective timetables, and preparation benefits. Excel academically while developing lifelong skills!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/exam-course'
        // },
        // {
        //     heading: 'Exam Prep Platform',
        //     text: "Explore Takeexamhelp: its features, advantages, and exam strategies. Embrace the convenience, flexibility, and immediate feedback!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/give-me-an-exam'
        // },
        // {
        //     heading: 'Exam Proficiency Guide',
        //     text: "Boost exam performance with Takeexamhelp's guidance! Master exam formats, utilize tools effectively, and receive valuable feedback. Build confidence to achieve success!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/exam-master'
        // },
        // {
        //     heading: 'Online Exam Simplified',
        //     text: "Step into the future of education with Takeexamhelp! Discover our user-friendly online exam platform.",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/just-online-exam'
        // },
        // {
        //     heading: 'Optimal Exam Timing',
        //     text: "Maximize exam success! Identify your peak hours, improve concentration, memory, and reduce stress. Excel in your exams!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/best-time-to-take-an-exam'
        // },
        // {
        //     heading: 'Top Exam Assistance',
        //     text: "Excel in your Takeexamhelp! Master advanced strategies, build confidence, and optimize your success!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/best-exam-help'
        // },
        // {
        //     heading: 'Efficient Exam Process',
        //     text: "EnrollExamNow: Efficient exams, flexible scheduling, excellent feedback. Streamline your academic journey!",
        //     imgLink: "",
        //     pageLink: '/SpecificeService/students-giving-exam'
        // },
    ];

    return (
        <>
            <div className="main-nav-banner-div-blog">
                <NavbarComp />
                <Banner
                    centeredText={'Blog'}
                    bannerHide={true}
                    blogBanner={true}
                />
            </div>
            <Container className='blog-div-container'>
                {
                    blogCardData.map((v, i) => {
                        return (
                            <Link
                                className='blog-card-div col-lg-4 col-md-4 col-sm-4 col-xs-12 p-3'
                                to={v.pageLink}
                                key={i}
                                data-aos={animationName}
                                data-aos-duration={animationDuration}
                            >
                                <div className='img-div-blog-card'>
                                    <img src={v.imgLink} alt={v.heading} />
                                </div>
                                <div className='text-div-blog-card'>
                                    <h2>
                                        {v.heading}
                                    </h2>
                                    <p>
                                        {v.text}
                                    </p>
                                </div>
                            </Link>

                        )
                    })
                }
            </Container>
            <Footer />
            <StickyFooter />
        </>
    )
}

export default BlogPage;


























