import './style.css';
import Navbar2Comp from '../../Components/Navbar2';
import Footer from '../../Components/Footer/index.jsx';
import StickyFooter from '../../Components/StickyFooter/index.jsx';
import { Container } from 'react-bootstrap';

const ContactUsPage = () => {

    return (
        <>
            <Navbar2Comp title={'Terms & Conditions'} />
            <Container className='terms-conditions-container'>
                <h5>Whom do we embody?</h5>
                <p>
                    Takexamhelp is distinguished as a leading service for exam preparation, supporting students globally to attain success in their exams. As an accredited and registered organization, we strictly follow rigorous policies, ensuring excellent academic assistance and delivering outstanding outcomes for our clients.
                    <br />
                    Upon form completion, review of Terms and Conditions, and engagement with our services, customers are accountable for compliance with all specified provisions. Violations of our outlined terms and conditions will result in adverse consequences.
                </p>
                <h5>Core Ideas</h5>
                <ul>
                    <li>
                        Request Status: Indicates the current stage of demand progression.
                    </li>
                    <li>
                        Revision: Represents a modified version of the client's primary product submission.
                    </li>
                    <li>
                        Support Department: Vital part of the company's dynamic framework, offering crucial assistance and coordinating order strategy implementation.
                    </li>
                    <li>
                        Quality Assurance Department: Ensures the rigorous monitoring and evaluation of product and service quality within the company's organizational structure.
                    </li>
                    <li>
                        Confirmation Process: Essential step for clients to verify their billing identity, safeguarding against undue influence or coercion.
                    </li>
                </ul>

                <h5>Payment Terms</h5>
                <p>
                    We only accept payments via online banking or credit card. We do not support other payment methods. Our payment system is secure and reliable, so you can shop with confidence.
                </p>
                <h5>Privacy Policy</h5>
                <p>
                    At Takexamhelp, safeguarding student privacy is our highest priority. Our Privacy Policy outlines how we handle all data collected through our website. Any information provided by students is exclusively used for communication purposes. We utilize cutting-edge encryption technologies to ensure the protection of both student and tutor information.
                </p>
                <h5>Instructional Guidance Techniques and Schedules</h5>
                <ul>
                    <li>
                        Students can schedule tutoring sessions on our website by logging in with their credentials. After logging in, they will be able to view a list of qualified tutors matched to their grade and chosen subject. Students can then select a tutor and see their availability for the upcoming six weeks.
                    </li>
                    <li>
                        Students can schedule up to 5 sessions in advance, based on the validity period of their learning package and their remaining credit balance, to accommodate their individual needs.
                    </li>
                    <li>
                        Teacher availability for specific dates and times cannot be guaranteed, as sessions are assigned on a first-come, first-served basis. To ensure a spot, students should book their appointments as early as possible.
                    </li>
                    <li>
                        You can reschedule or cancel sessions on the website up to 24 hours prior to the start time.
                    </li>
                    <li>
                        Rescheduling or canceling sessions is not allowed within 24 hours of the scheduled start time.
                    </li>
                    <li>
                        If a student misses a scheduled session without prior notification, it will be recorded as an absence. Students who accumulate two or more absences within a month may face restrictions on scheduling with Takexamhelp for the subsequent three months.
                    </li>
                    <li>
                        Students need to sign up for their session on the website no less than five minutes prior to the start time. A grace period of ten minutes will be provided before the tutor concludes the session.
                    </li>
                    <li>
                        In the event that a class examination is canceled or rescheduled due to technical or operational issues, Takexamhelp will promptly reach out to the student via phone or email to provide an alternative time or tutor. Should a rescheduling arrangement not be agreed upon within seven (7) days, the student is entitled to request a full refund of the fees paid for the canceled sessions.
                    </li>
                </ul>
            </Container>
            <Footer />
            <StickyFooter />
        </>
    )
}

export default ContactUsPage;