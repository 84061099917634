import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const MarketingClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Engage a Professional for my Nursing Course!"
                    text={`
        Explore exceptional support for your nursing online course available now, aimed to help you with queries like, 'Could someone complete my nursing online course?' Our team includes specialists and experienced educators, ensuring full participation in your classes. Make your request today!
    `}
                    list={[
                        'Experienced Professionals',
                        'Safe Payment Process',
                        'Strict Security Measures',
                    ]}
                />

            </div>
            <Sec2
                Icon1Text={'Complete Dependability'}
                Icon2Text={'Continuous Availability'}
                Icon3Text={<>Expertly trained <br /> individuals</>}
                Icon4Text={'Assured Highest Grade'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default MarketingClassHelpPage;