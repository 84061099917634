import NavbarComp from '../../../Components/Navbar';
import Banner from '../../../Sections/Banner/index.jsx';
import Sec2 from '../../../Sections/Sec2/index.jsx';
import Service from '../../../Sections/Service/index.jsx';
import Courses from '../../../Sections/Courses/index.jsx';
import Sec3 from '../../../Sections/Sec3/index.jsx';
import Sec4 from '../../../Sections/Sec4/index.jsx';
import Sec5 from '../../../Sections/Sec5/index.jsx';
import SubjectSec from '../../../Sections/SubjectSec/index.jsx';
import Testimonial from '../../../Sections/Testimonial/index.jsx';
import Footer from '../../../Components/Footer/index.jsx';
import StickyFooter from '../../../Components/StickyFooter/index.jsx';


const FinanceClassHelpPage = () => {
    return (
        <>
            <div className="main-nav-banner-div">
                <NavbarComp />
                <Banner
                    title="Engage a Specialist for My Finance Course!"
                    text={`
        As expert providers of finance course support, we make sure your classes are engaging and productive. When you reach out to us with a 'take my finance class for me' request, all your concerns dissolve. Our team consists of qualified and experienced finance course assistants who ensure 100% participation in your online finance class.
    `}
                    list={[
                        'Assured Grades',
                        'Available Anytime',
                        'Packages Tailored for Students',
                    ]}
                />
            </div>
            <Sec2
                Icon1Text={'Policy for Reimbursement'}
                Icon2Text={'Domain Specialists'}
                Icon3Text={'100% Private'}
                Icon4Text={'Timeliness Guaranteed'}
            />
            <Service />
            <Courses />
            <Sec4 />
            <Sec3 />
            <Sec5 />
            <SubjectSec />
            <Testimonial />
            <Footer />
            <StickyFooter />
        </>
    )
}

export default FinanceClassHelpPage;