import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';  
import { useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';
// Pages
import HomePage from './Pages/Home';
import ContactUsPage from './Pages/ContactUs';
import TermsAndConditionsPage from './Pages/TermsAndConditions';
import BlogPage from './Pages/Blog';
import SpecificeServicePage from './Pages/SpecificeService';
// Services 1
import OnlineClassHelpPage from './Pages/Services/Online-Class-Help';
import OnlineCourseHelpPage from './Pages/Services/Online-Course-Help';
import OnlineExamHelpPage from './Pages/Services/Online-Exam-Help';
import PraxisExamHelpPage from './Pages/Services/Praxis-Exam-Help';
import ComptiaExamHelpPage from './Pages/Services/Comptia-Exam-Help';
import GedExamHelpPage from './Pages/Services/Ged-Exam-Help';
import GreExamHelpPage from './Pages/Services/Gre-Exam-Help';
import PmpExamHelpPage from './Pages/Services/Pmp-Exam-Help';
import RealEstateExamHelpPage from './Pages/Services/Real-Estate-Exam-Help';
import CertificationExamHelpPage from './Pages/Services/Certification-Exam-Help';
import HesiExamHelpPage from './Pages/Services/Hesi-Exam-Help';
import TeasExamHelpPage from './Pages/Services/Teas-Exam-Help';
// Services 2
import AccountingClassHelpPage from './Pages/Services2/Accounting-Class-Help';
import AlgebraClassHelpPage from './Pages/Services2/Algebra-Class-Help';
import ChemistryClassHelpPage from './Pages/Services2/Chemistry-Class-Help';
import FinanceClassHelpPage from './Pages/Services2/Finance-Class-Help';
import EconomicClassHelpPage from './Pages/Services2/Economic-Class-Help';
import LawClassHelpPage from './Pages/Services2/Law-Class-Help';
import MarketingClassHelpPage from './Pages/Services2/Marketing-Class-Help';
import NursingClassHelpPage from './Pages/Services2/Nursing-Class-Help';
import StatisticsClassHelpPage from './Pages/Services2/Statistics-Class-Help';
import BusinessClassHelpPage from './Pages/Services2/Business-Class-Help';
//AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';



const RotesPages = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route element={<HomePage />} path="/" />
                <Route element={<ContactUsPage />} path="/contact-us" />
                <Route element={<TermsAndConditionsPage />} path="/terms-and-conditions" />
                <Route element={<BlogPage />} path="/blog" />
                <Route element={<SpecificeServicePage />} path="/SpecificeService/:topicName" />
                <Route element={<OnlineClassHelpPage />} path="/online-class-help" />
                <Route element={<OnlineCourseHelpPage />} path="/online-course-help" />
                <Route element={<OnlineExamHelpPage />} path="/online-exam-help" />
                <Route element={<PraxisExamHelpPage />} path="/praxis-exam-help" />
                <Route element={<ComptiaExamHelpPage />} path="/comptia-exam-help" />
                <Route element={<GedExamHelpPage />} path="/ged-exam-help" />
                <Route element={<GreExamHelpPage />} path="/gre-exam-help" />
                <Route element={<PmpExamHelpPage />} path="/pmp-exam-help" />
                <Route element={<RealEstateExamHelpPage />} path="/real-estate-exam-help" />
                <Route element={<CertificationExamHelpPage />} path="/certification-exam-help" />
                <Route element={<HesiExamHelpPage />} path="/hesi-exam-help" />
                <Route element={<TeasExamHelpPage />} path="/teas-exam-help" />
                <Route element={<AccountingClassHelpPage />} path="/accounting-class-help" />
                <Route element={<AlgebraClassHelpPage />} path="/algebra-class-help" />
                <Route element={<ChemistryClassHelpPage />} path="/chemistry-class-help" />
                <Route element={<FinanceClassHelpPage />} path="/finance-class-help" />
                <Route element={<EconomicClassHelpPage />} path="/economic-class-help" />
                <Route element={<LawClassHelpPage />} path="/law-class-help" />
                <Route element={<MarketingClassHelpPage />} path="/marketing-class-help" />
                <Route element={<NursingClassHelpPage />} path="/nursing-class-help" />
                <Route element={<StatisticsClassHelpPage />} path="/statistics-class-help" />
                <Route element={<BusinessClassHelpPage />} path="/business-class-help" />
            </Routes>
        </Router>
    )
}
export default RotesPages