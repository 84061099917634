import { Container } from 'react-bootstrap';
import './style.css';
import { BiMessage } from "react-icons/bi";



const Courses = () => {
    return (
        <div className='course-div'>
            <Container>
                <div className='hd-div'>
                    <h1>
                        Our Popular Courses
                    </h1>
                    <p>Helping students make an exceptional academic mark in all subjects</p>
                </div>
                <div className='course-card-container'>
                    <div className='course-card-div col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                        <div className='sub-course-card-div'>
                            <span>
                                <BiMessage className='comment-section' />
                            </span>
                            <h3 className='course-card-hd'>Economics </h3>
                            <p>
                                Does the complexity of economics leave you baffled? Rest assured, our subject
                                specialists are here to help. We excel in securing high grades for you in online
                                classes, courses, and exams in Economics.
                            </p>
                            <div className="box_border"></div>
                        </div>
                    </div>
                    <div className='course-card-div col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                        <div className='sub-course-card-div sub-course-card-div-2'>
                            <span>
                                <BiMessage className='comment-section' />
                            </span>
                            <h3 className='course-card-hd'>Law</h3>
                            <p>
                                Law, the pivotal yet intricate subject, often poses challenges for students.
                                But fear not! Our legal experts simplify the complexities, ensuring clarity
                                and ease in your studies.
                            </p>
                            <div className="box_border"></div>
                        </div>
                    </div>
                    <div className='course-card-div col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                        <div className='sub-course-card-div sub-course-card-div-3'>
                            <span>
                                <BiMessage className='comment-section' />
                            </span>
                            <h3 className='course-card-hd'>Business</h3>
                            <p>
                                Understanding business is essential in today's entrepreneurial landscape.
                                Our expert assistance ensures you grasp the intricacies of the Business
                                subject with ease.
                            </p>
                            <div className="box_border"></div>
                        </div>
                    </div>
                    <div className='course-card-div col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='sub-course-card-div sub-course-card-div-4'>
                            <span>
                                <BiMessage className='comment-section' />
                            </span>
                            <h3 className='course-card-hd '>Chemistry</h3>
                            <p>
                                Chemistry may seem daunting to many, but not to our specialists. They excel
                                in solving chemical formulas and understanding chemical bonds with proficiency.
                            </p>
                            <div className="box_border"></div>
                        </div>
                    </div>
                    <div className='course-card-div col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='sub-course-card-div sub-course-card-div-5'>
                            <span>
                                <BiMessage className='comment-section' />
                            </span>
                            <h3 className='course-card-hd'>Geology</h3>
                            <p>
                                Geology is a subject of immense importance today, focusing on Earth's mysteries,
                                natural resources, and climate. Let Takeonlinecourse guide you through its
                                complexities and scientific insights.
                            </p>
                            <div className="box_border"></div>
                        </div>
                    </div>

                </div>
            </Container>
        </div>
    )
}

export default Courses;